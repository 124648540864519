<template>
  <div class="modern-color-theme font-poppins flex flex-col gap-2" data-component-name="VInput">
    <VSLabel v-if="props.label" :tooltip="props.labelTooltip" :for="id">{{ props.label }}</VSLabel>
    <div
      class="flex items-center rounded-md ring-1 ring-inset focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600"
      :class="{
        'text-neutral-400 bg-neutral-150 ring-neutral-200': props.disabled,
        'text-neutral-950 bg-neutral-100 ring-neutral-300': !props.disabled
      }"
    >
      <div v-if="slots.prefix" class="pl-2"><slot name="prefix" /></div>
      <input
        :id="id"
        ref="focusElement"
        v-model="modelValueInternal"
        :type="props.type"
        :disabled="props.disabled"
        :readonly="props.readonly"
        :maxlength="props.maxLength"
        :placeholder="props.placeholder" 
        class="w-full rounded-md m-[2px] pl-2.5 !border-none focus:ring-0 !shadow-none py-[calc(0.375rem-2px)] shadow-sm placeholder:text-neutral-400 text-sm leading-6"
        :class="{
          'text-neutral-400 bg-neutral-150 ring-neutral-200': props.disabled,
          'text-neutral-950 bg-neutral-100 ring-neutral-300': !props.disabled,
          'text-center': props.centered
        }"
        @keydown="validationActive = true"
        @keydown.enter="updateModelValue"
        @blur="updateModelValue"
      >
      <div v-if="props.maxLength" class="pr-4 whitespace-nowrap text-xs leading-4 font-normal" :class="{ 'text-neutral-500': !props.disabled, 'text-neutral-250': props.disabled }">
        {{ modelValueInternalLength }} / {{ props.maxLength }}
      </div>
      <div v-if="slots.suffix" class="pr-2"><slot name="suffix" /></div>
    </div>
    <VSDescription v-if="props.description">{{ props.description }}</VSDescription>
    <VAlert v-if="validationResult" class="mt-2" dense :type="validationResult[0]" :message="validationResult[1]" />
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, useSlots, watch } from 'vue';
import type { ValidationResult } from '../../utils/validations';
import VSDescription from './components/VSDescription.vue'
import VSLabel from './components/VSLabel.vue'
import VAlert from '../dialogs/VAlert.vue'
import { useElementId } from '../../utils/utils';
import { useFocus } from '@component-utils/focus';

defineOptions({
  name: 'VInput'
})

const slots = useSlots()

const props = withDefaults(
  defineProps<{
    id?: string
    label?: string
    labelTooltip?: string
    description?: string
    placeholder?: string
    disabled?: boolean
    readonly?: boolean
    type?: 'text' | 'password'
    focus?: boolean
    centered?: boolean
    maxLength?: number
    buffered?: boolean
    validator?: (value: undefined | null | string) => ValidationResult
  }>(),
  {
    id: undefined,
    type: 'text',
    label: undefined,
    labelTooltip: undefined,
    placeholder: undefined,
    description: undefined,
    focus: false,
    validator: undefined,
    centered: false,
    maxLength: undefined,
    buffered: false
  }
)

const id = useElementId(props.id)

const modelValue = defineModel<undefined | null | string>({ required: true })
const modelValueInternal = ref(modelValue.value)

watch(modelValue, (value) => modelValueInternal.value = value)
watch(modelValueInternal, (value) => {
  if (!props.buffered) modelValue.value = value
})

const modelValueInternalLength = computed(() => modelValueInternal.value?.length ?? 0)

const updateModelValue = () => {
  if (props.buffered) modelValue.value = modelValueInternal.value
}

const validationActive = ref(false)
const validationResult = computed(() => props.validator && validationActive.value && props.validator(modelValueInternal.value) || null)

const { focus, focusElement } = useFocus(props.focus)

defineExpose({
  focus
})
</script>